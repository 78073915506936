<template>
    <keep-alive>
        <router-view :key="$route.fullPath"></router-view>
    </keep-alive>
</template>

<script>
    export default {
        name: 'Users',
        data() {
            return {}
        },
        components: {},
        methods: {},
        computed: {},
        mounted() {
            //this.$store.dispatch("requestCurrentTwitterUsers")
        },
    destroyed() {
        console.log("Users destroyed");
    }
    }
</script>
